import React from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import GeneralServices from '../../../app/service/generalServices'
import ConstantsUtil from '../../../context/constantsUtil'
import { Button } from 'primereact/button'
import EditProvisaoDialog from './editProvisaoDialog'
import ConfirmationDialog from '../../confirmationDialog'
import ProvisaoService from '../../../app/service/contasAPagar/provisaoService'
import * as popUp from '../../toastr'
import HandleErrorService from '../../../app/service/handleErrorService'
import TableFilters from '../../tableFilters'
import TooltipButton from '../../tooltipButton'
import CentralService from '../../../app/service/central/centralService'

class ProvisaoTable extends React.Component {

    state = {
        visibleConfirmDeleteDialog: false,
        fichaDeEstoqueToDelete: '',

        // valorTotal: null,
        filterredList: null,

        loading: false,

        displayEditDialog: false,
        idProvsisaoToEdit: '',
        empresaProvisaoToEdit: '',

        displayConfirmDeleteDialog: false,
        idProvisaoToDelete: '',
        empresaProvisaoToDelete: '',
    }

    constructor(){
        super();
        this.dt = React.createRef()
        this.tableFilters = new TableFilters();
        this.provisaoService = new ProvisaoService();
        this.generalServices = new GeneralServices();
        this.centralService = new CentralService();
    }

    doFilterTipoDePagamento = async (value) => {
        this.setState({selectedTipoDePagamento: value})
        this.dt.current.filter(value, ConstantsUtil.tipoDePagamentoNomeProperty, 'in');
    }

    isFiltroDataLancamento = () => {
        if(this.props.searchProvisaoResult){
            return this.props.searchProvisaoResult.filterTypeDate === ConstantsUtil.dataLancamentoValue
        }

        return true
    }

    getList(){
        if(!this.props.searchProvisaoResult){
            return []
        }

        if(this.isFiltroDataLancamento()){
            //Filtrado por data de lançamento
            return this.props.searchProvisaoResult.provisaoList
        }

        //Filtrado por data de pagamento
        return this.props.searchProvisaoResult.parcelaProvisaoList

    }

    getValorColumnLabel = () => {
        if(this.isFiltroDataLancamento()){
            return "Valor da Provisão"
        }

        return "Valor da Parcela"

    }

    getTableHeaders = () => {
        
        const filterTypeDate = this.props.searchProvisaoResult && this.props.searchProvisaoResult.filterTypeDate ?
            this.isFiltroDataLancamento() ? ConstantsUtil.dataLancamentoLabel : ConstantsUtil.dataPagamentoLabel
            : ''
            
        const filterTypeLabel = "Filtrado por: " + filterTypeDate

        const periodo = this.props.searchProvisaoResult && this.props.searchProvisaoResult.initialDate ? this.props.searchProvisaoResult.initialDate + " - " + this.props.searchProvisaoResult.finalDate : ""
        const periodoLabel = "Período: " + periodo

        return {filterTypeDate: filterTypeLabel, periodo: periodoLabel}
    }

    getIdProvisao = provisaoOuParcela => {
        let provisaoId;

        if(this.isFiltroDataLancamento()){
            //Filtrado por data de lançamento
            provisaoId = provisaoOuParcela.id
        }
        else{
            provisaoId = provisaoOuParcela.provisaoId
        }

        return provisaoId;

    }

    editProvisao = async provisaoOuParcela => {

        await this.setState({idProvsisaoToEdit: this.getIdProvisao(provisaoOuParcela)})
        await this.setState({empresaProvisaoToEdit: provisaoOuParcela.empresa})
        this.setState({displayEditDialog: true})
        
    }

    hideEditDialog = async () => {
        await this.setState({displayEditDialog: false})
        await  this.setState({idProvsisaoToEdit: ''})
        await  this.setState({empresaProvisaoToEdit: ''})
    }

    
    callDeleteProvisao = async (provisaoOuParcela) => {
        await this.setState({idProvisaoToDelete: this.getIdProvisao(provisaoOuParcela)})
        await this.setState({empresaProvisaoToDelete: provisaoOuParcela.empresa})
        this.setState({displayConfirmDeleteDialog: true})

    }

    handleDeleteProvisaoById = () => {
        if(this.state.empresaProvisaoToDelete){
            return this.centralService.deleteProvisaoById(this.state.idProvisaoToDelete, this.state.empresaProvisaoToDelete.id)
        }
        else{
            return this.provisaoService.deleteById(this.state.idProvisaoToDelete)
        }
    }

    confirmDeleteProvisao = () => {

        this.setState({loading: true})
        this.hideConfirmDeleteDialog()

        this.handleDeleteProvisaoById()
        .then(response => {
            popUp.successPopUp("Provisão Deletada com Sucesso!")
            this.props.search(true)
        })
        .catch(error => {
            HandleErrorService.handleError(error)
        })
        .finally(() => {
            this.setState({loading: false})
        })

    }

    hideConfirmDeleteDialog = () => {
        this.setState({displayConfirmDeleteDialog: false})
        this.setState({idProvisaoToDelete: ''})
        this.setState({empresaProvisaoToDelete: ''})
    }

    getValorTotal = () => {

        try{
            let list;
            // console.log("this.state.filterredList: ", this.state.filterredList)
            // console.log("this.getList(): ", this.getList())
            if(this.state.filterredList){
                // console.log("entrou no filterredList")
                list = JSON.parse(JSON.stringify(this.state.filterredList))
            }
            else{
                list = JSON.parse(JSON.stringify(this.getList()))
            }
            // console.log("lista final: ", list)
            let result = GeneralServices.valueBodyTemplate(this.calculaValorTotal(list, false))
            // console.log("result: ", result)
            return result
        }
        catch(e) {
            console.log(e)
            return GeneralServices.valueBodyTemplate(0)
        }
    }

    calculaValorTotal = (currentTableList, isFilter) => {

        // console.log("calcualValorTotal: ", currentTableList)
        
        let valorTotal = 0
        currentTableList.forEach(item => {
            if(this.isFiltroDataLancamento()){
                valorTotal += item.valorTotal
            }
            else{
                valorTotal += item.valor
            }
        })
        // this.setState({valorTotal})

        if(this.props.calculaValorTotal){
            const filterTypeDate = this.props.searchProvisaoResult.filterTypeDate
            this.props.calculaValorTotal({
                filterTypeDate: filterTypeDate,
                provisaoList: filterTypeDate === ConstantsUtil.dataLancamentoValue ? currentTableList : [],
                parcelaProvisaoList: filterTypeDate === ConstantsUtil.dataPagamentoValue ? currentTableList : []
            })
        }

        if(isFilter){
            this.setState({filterredList: currentTableList})
        }
        // console.log("valor total: ", valorTotal)
        return valorTotal
    }

    search = (fromTable) => {
        this.props.search(fromTable)
        this.setState({loading: false})
    }

    render(){

        const renderValorTotalProvisaoColumn = () => {
            if(!this.isFiltroDataLancamento()){
                return (
                    <Column
                        header="Valor Total da Provisão"
                        field="valorTotalProvisao" 
                        body = {rowData => GeneralServices.showFormattedIfNotNull(rowData.valorTotalProvisao, true)}
                        sortable style ={ {width: '50px'} }
                        filter filterElement={valorTotalProvisaoFilterElement}
                    />               
                )
            }
        }

        const renderEmpresaColumn = () => {
            if(
                this.isFiltroDataLancamento()
                && this.props.searchProvisaoResult
                && this.props.searchProvisaoResult.unificado
            )
            {
                return (
                    <Column
                        header="Empresa"
                        field="empresa.nome"
                        // body = {rowData => GeneralServices.showFormattedIfNotNull(rowData.valorTotalProvisao, true)}
                        sortable style ={ {width: '40px'} }
                        filter filterElement={empresaFilterElement}
                    />
                )
            }
        }

        const renderActionColumn = () => {
            if(this.props.perfil && !this.props.perfil.isDiretor && !this.props.disabled){
                return (
                    <Column header="Ações" body={actionBody} style ={ {width: '2.5rem'} }
                    />
                )
            }

        }

        const actionBody = (provisaoOuParcela) => {
            return (
                <React.Fragment>
                    <TooltipButton
                        tooltip='Editar'
                        icon="pi pi-pencil"
                        onClick={() => this.editProvisao(provisaoOuParcela)}
                    />

                    <TooltipButton
                        tooltip='Excluir'
                        className={ "p-button-rounded p-button-danger p-mr-2 right-button" }
                        icon="pi pi-times"
                        onClick={() => this.callDeleteProvisao(provisaoOuParcela)}
                    />

                </React.Fragment>
            );
        }

        //data Filter
        const dateFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedDate,
            this.getList(),
            "selectedDate",
            (name, value) => this.setState({[name]: value}),
            'data',
            '80px',
            "Selecione"
        )

        //valorProvisao Filter
        const valorProvisaoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedValorProvisao,
            this.getList(),
            "selectedValorProvisao",
            (name, value) => this.setState({[name]: value}),
            'valor',
            '80px',
            "Selecione"
        )

        //valor Total Provisao Filter (Apresentado apenas quando o filtro for Data de Lançamento)
        const valorTotalProvisaoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedValorTotalProvisao,
            this.getList(),
            "selectedValorTotalProvisao",
            (name, value) => this.setState({[name]: value}),
            'valorTotalProvisao',
            '80px',
            "Selecione"
        )

        //empresa Provisao Filter (Apresentado apenas quando o filtro for Data de Lançamento)
        const empresaFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedEmpresaProvisao,
            this.getList(),
            "selectedEmpresaProvisao",
            (name, value) => this.setState({[name]: value}),
            'empresa.nome',
            // 'nomeFornecedor',
            '100%',
            "Selecione"
        )


        //Fornecedor Filter
        const nomeFornecedorFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedNomeFornecedor,
            this.getList(),
            "selectedNomeFornecedor",
            (name, value) => this.setState({[name]: value}),
            'nomeFornecedor',
            '100px',
            "Selecione"
        )

        //Tipo de Pagamento Filter
        const tipoDePagamentoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedTipoDePagamento,
            this.getList(),
            "selectedTipoDePagamento",
            (name, value) => this.setState({[name]: value}),
            ConstantsUtil.tipoDePagamentoNomeProperty,
            '100px',
            "Selecione"
        )

        //Grupo de Pagamento Filter
        const grupoDePagamentoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedGrupoDePagamento,
            this.getList(),
            "selectedGrupoDePagamento",
            (name, value) => this.setState({[name]: value}),
            'tipoDePagamento.grupoDePagamento.nome',
            '100px',
            "Selecione"
        )

        //N° Doc Filter
        const numeroDocumentoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedNumeroDocumento,
            this.getList(),
            "selectedNumeroDocumento",
            (name, value) => this.setState({[name]: value}),
            'numeroDocumento',
            '80px',
            "Selecione"
        )

        //Origem Filter
        const origemFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedOrigens,
            this.getList(),
            "selectedOrigens",
            (name, value) => this.setState({[name]: value}),
            'origem',
            '100%',
            "Selecione"
        )

        //Histórico Filter
        const historicoFilterElement = this.tableFilters.renderTableFilter(
            this.dt,
            this.state.selectedHistorico,
            this.getList(),
            "selectedHistorico",
            (name, value) => this.setState({[name]: value}),
            'historico',
            '120px',
            "Selecione"
        )


        return (
            <>
            <div className = "card-header">
            <div className='col-md-12'>
                <div className='row'>
                    <div className='col-md-4'>
                        <h5 className='medium-font-size'>{this.getTableHeaders().filterTypeDate}</h5>
                    </div>
                    <div className='col-md-4'>
                        <h5 className='medium-font-size'>{this.getTableHeaders().periodo}</h5>
                    </div>
                    <div className='col-md-4 d-flex justify-content-end'>
                        <Button
                            label={ (this.isFiltroDataLancamento() ? 'Valor Total' : 'Valor Total das Parcelas') + ": " + this.getValorTotal()}
                            className="p-button-success"
                        />
                    </div>
                </div>
                
            </div>
            </div>

            <DataTable
                ref={this.dt}
                value={this.getList()}
                className="p-datatable-sm small-font"
                rowHover
                showGridlines
                scrollable
                scrollHeight="500px"
                emptyMessage={ConstantsUtil.nenhumLancamentoEncontradoMessage}
                loading={this.props.loading || this.state.loading}
                dataKey="id"
                onValueChange={filterredList => this.calculaValorTotal(filterredList, true)}
                paginator rows={ConstantsUtil.initialTableQuantityOption}
                paginatorTemplate={GeneralServices.tablePaginatorTemplate(ConstantsUtil.initialTableQuantityOption, this.getList().length)}
                currentPageReportTemplate="Mostrando de {first} ao {last} de {totalRecords} lançamentos"
            >

            <Column
                header={this.isFiltroDataLancamento() ? "Data de Lançamento" : "Data De Pagamento"}
                field="data"
                // body={rowData => rowData.dataString}
                sortable style ={ {width: '35px'} }
                filter filterElement={dateFilterElement}
             />

            <Column
                header={this.getValorColumnLabel()}
                field="valor" 
                // body = {rowData => renderViewLancamentosLink(rowData, rowData.entrada, false, rowData.tipo !== ConstantsUtil.saldoLabel, ConstantsUtil.entradaLabel)}
                body = {rowData => GeneralServices.showFormattedIfNotNull(rowData.valor, true)}
                sortable style ={ {width: '40px'} }
                filter filterElement={valorProvisaoFilterElement}
                />

            {renderValorTotalProvisaoColumn()}

            <Column
                header="Fornecedor"
                field='nomeFornecedor'
                body = { (rowData, rowIndex) => GeneralServices.renderBodyColumnInOneLine(rowData.nomeFornecedor, rowIndex)}
                sortable style ={ {width: '50px'} }
                filter filterElement={nomeFornecedorFilterElement}
            />

            <Column
                header="Tipo de Pagamento"
                field={ConstantsUtil.tipoDePagamentoNomeProperty}
                sortable style ={ {width: '70px'} }
                filter filterElement={tipoDePagamentoFilterElement}
            />

            <Column
                header="Grupo de Pagamento"
                field='tipoDePagamento.grupoDePagamento.nome'
                body = { (rowData, rowIndex) => GeneralServices.renderBodyColumnInOneLine(rowData.tipoDePagamento.grupoDePagamento.nome, rowIndex)}
                sortable style ={ {width: '70px'} }
                filter filterElement={grupoDePagamentoFilterElement}
                />


            <Column
                header="N° Documento"
                field="numeroDocumento"
                // body = {rowData => renderViewLancamentosLink(rowData, rowData.saldo, true, rowData.tipo === ConstantsUtil.saldoLabel, ConstantsUtil.saldoLabel)}
                sortable style ={ {width: '50px'} }
                filter filterElement={numeroDocumentoFilterElement}
            />

            <Column
                header="Origem da Provisão"
                field='origem'
                body = { (rowData, rowIndex) => GeneralServices.renderBodyColumnInOneLine(rowData.origem, rowIndex)}
                sortable style ={ {width: '60px'} }
                filter filterElement={origemFilterElement}
            />

            {renderEmpresaColumn()}

            <Column
                header="Histórico"
                field="historico"
                body = { (rowData, rowIndex) => GeneralServices.renderBodyColumnInOneLine(rowData.historico, rowIndex)}
                sortable style ={ {width: '70px'} }
                filter filterElement={historicoFilterElement}
            />

            
            {renderActionColumn()}

        </DataTable>

        <EditProvisaoDialog
            visible={this.state.displayEditDialog}
            provisaoId={this.state.idProvsisaoToEdit}
            empresa={this.state.empresaProvisaoToEdit}
            // search={this.props.search}
            search={this.search}
            startLoadingTable={() => this.setState({loading: true})}
            finishLoadingTable={() => this.setState({loading: false})}
            hideDialog={this.hideEditDialog}
        />

        <ConfirmationDialog
            visible={this.state.displayConfirmDeleteDialog}
            header={"Deletar Lançamento de Provisão"}
            confimationMessage={ "Deseja Confirmar?"}
            confirm={this.confirmDeleteProvisao}
            hide={this.hideConfirmDeleteDialog}
        />        

        </>
        )
    }

}


export default ProvisaoTable