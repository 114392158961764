import CentralApiService from "../../centralApiServices";


class EmpresaService extends CentralApiService {

    constructor() {
        super('/api/empresa')
    }

    getAllEmpresas(){
        return this.get('/empresas')
    }

    getLoggedEmpresas(){
        return this.get('/loggedEmpresas')
    }

    createOrUpdate(empresa){
        return this.post("/createOrUpdate", empresa)
    }
    
    changeStatus(empresa){
        return this.put("/changeStatus", empresa)
    }

    buscaDadosSyncNFsVenda(empresa){
        let params = `?`
        params = `${params}&empresaId=${empresa.id}`
        return this.get(`/buscaDadosSyncNFsVenda${params}`)
    }

    syncNFsVenda(dados){
        return this.post(`/syncNFsVenda`, dados)
    }

}

export default EmpresaService