import CentralApiService from "../../centralApiServices";


class CentralService extends CentralApiService {

    constructor() {
        super('/api/central')
    }

    searchWorkers(){
        return this.get('/workers')
    }

    getDREMensalUnificado(dataInicial, dataFinal, selectedEmpresas){
        let params = `?`
        params = `${params}&dataInicial=${dataInicial}`
        params = `${params}&dataFinal=${dataFinal}`
        return this.post(`/DRE/getDREMensalUnificado${params}`, selectedEmpresas)
    }

    getDREConsolidadoUnificado(dataInicial, dataFinal, selectedEmpresas){
        let params = `?`
        params = `${params}&dataInicial=${dataInicial}`
        params = `${params}&dataFinal=${dataFinal}`
        return this.post(`/DRE/getDREConsolidadoUnificado${params}`, selectedEmpresas)
    }

    getFichaBancariaUnificada(data){
        return this.post(`/DRE/getFichaBancariaUnificada`, data)
    }

    getDRECompetenciaMensalUnificado(dataInicial, dataFinal, selectedEmpresas){
        let params = `?`
        params = `${params}&dataInicial=${dataInicial}`
        params = `${params}&dataFinal=${dataFinal}`
        return this.post(`/DRECompetencia/getDRECompetenciaMensalUnificado${params}`, selectedEmpresas)
    }

    searchLancamentosProvisao(searchProvisaoInputDTO, empresasList){
        const objFinal = {
            searchProvisaoInputDTO,
            empresasList
        }
        return this.post("/DRECompetencia/searchLancamentosProvisaoUnificado", objFinal)
    }

    searchProvisaoProdutosUnificado(initialDate, finalDate, tipoProdutoList, empresasList){
        const objFinal = {
            initialDate,
            finalDate,
            tipoProdutoList,
            empresasList
        }
        return this.post("/DRECompetencia/provisaoProdutosUnificado", objFinal)
    }

    searchProvisaoByIdAndEmpresa(provisaoId, empresaId){
        let params = `?`
        params = `${params}&provisaoId=${provisaoId}`
        params = `${params}&empresaId=${empresaId}`
        return this.get(`/DRECompetencia/searchProvisaoByIdAndEmpresa${params}`)        
    }

    editProvisao(object, empresaId){
        let params = `?`
        params = `${params}&empresaId=${empresaId}`        
        return this.put(`/DRECompetencia/editProvisao${params}`, object)
    }

    deleteProvisaoById(provisaoId, empresaId){
        return this.delete(`/DRECompetencia/deleteProvisaoById/${provisaoId}/${empresaId}`)
    }    
    
    getFichaDeEstoqueByDateInterval(body){
        return this.post("/fichaDeEstoque/searchByDateInterval", body)
    }

    checkAberturaInventario(productUuid){
        let params = `?`
        params = `${params}&uuid=${productUuid}`
        return this.get(`/fichaDeEstoque/checkAberturaInventario${params}`)        
    }


}

export default CentralService